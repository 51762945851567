/* You can add global styles to this file, and also import other style files */

@import 'material-icons/iconfont/material-icons.css';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body {
  height: 100%;
}

body {
  margin: 0;
  min-width: 400px;
}

a:hover {
  color: inherit;
}

::selection {
  background-color: var(--primary);
  color: var(--primary-text-contrast);
  text-shadow: none;
}

.shell-menu {
  border-top: solid 3px var(--primary);

  .mat-menu-content {
    padding-top: 0 !important;

    .group {
      margin-top: -1px;
      border-top: 1px solid var(--gray-light);
      border-bottom: 1px solid var(--gray-light);

      .caption {
        padding: 1em 0 0 16px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: default;
      }
    }

    .mat-menu-item {
      font-size: 13px;
      font-weight: 600;

      &:hover {
        background-color: unset;
        color: var(--primary);

        .mat-icon {
          color: var(--primary);
        }
      }
    }
  }

  .head {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    background-color: var(--gray-light);
    cursor: default;
    font-size: 13px;
    font-weight: 600;
    color: var(--primary-text);

    .mat-icon {
      margin: 0 16px 0 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      line-height: 1.5em;
      width: calc(100% - 40px);

      .name, .email {
        overflow-x: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .email {
        font-size: 0.9em;
        font-weight: 500;
      }
    }
  }
}
