@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-display: swap;
    font-weight: 125 950;
    font-stretch: 75% 125%;
	src: url('assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: OpenSans;
  src: url(assets/fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: normal;
  src: url(assets/fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: normal;
  font-style: italic;
  src: url(assets/fonts/OpenSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: OpenSans;
  font-weight: bold;
  font-style: italic;
  src: url(assets/fonts/OpenSans-BoldItalic.ttf) format('truetype');
}

/* fallback */
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/material-icons-outlined.woff2) format('woff2');
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
