@use '@angular/material' as mat;
@import '@angular/material/theming';

$fontConfig: mat.define-typography-config(
  // General font setting
  $font-family: 'OpenSans, sans-serif',

  // One-off headers, usually at the top of the page (e.g. a hero header)
  $display-4: mat.define-typography-level(84px, 1.3em),
  $display-3: mat.define-typography-level(43px, 1.3em),
  $display-2: mat.define-typography-level(34px, 1.3em),
  $display-1: mat.define-typography-level(28px, 1.3em),

  // Section heading corresponding to the <h1> tag
  $headline: mat.define-typography-level(24px, 1.3em, 700),
  // Section heading corresponding to the <h2> tag
  $title: mat.define-typography-level(16px, 1.3em, 700),
  // Section heading corresponding to the <h3> tag
  $subheading-2: mat.define-typography-level(14px, 1.3em, 700),
  // Section heading corresponding to the <h4> tag
  $subheading-1: mat.define-typography-level(12px, 1.3em),
  // Bolder body text
  $body-2: mat.define-typography-level(14px, 1.5em, 700),
  // Base body text
  $body-1: mat.define-typography-level(14px, 1.5em),
  // Smaller body and hint text
  $caption: mat.define-typography-level(12px, 1.5em),
  // Buttons and anchors
  $button: mat.define-typography-level(13px, 1.5em, 600),
);

@include mat.core($fontConfig);

$palette-green: (
  50: #e7f6e8,
  100: #c6e8c7,
  200: #a2d9a3,
  300: #7bcb7e,
  400: #5dc061,
  500: #3db444,
  600: #34a53b,
  700: #279330,
  800: #1a8225,
  900: #006310,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$palette-orange: (
  50: #fcf2e1,
  100: #f8deb3,
  200: #f4ca82,
  300: #f0b451,
  400: #eda42f,
  500: #eb9616,
  600: #e78b13,
  700: #e17c0f,
  800: #db6d0b,
  900: #d05608,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$palette-red: (
  50: #ffebef,
  100: #ffcdd3,
  200: #ff9999,
  300: #f87172,
  400: #ff4f4e,
  500: #ff3e31,
  600: #f83432,
  700: #e5282c,
  800: #d82025,
  900: #c81118,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);

$theme-primary: mat.define-palette($palette-green, 700, 300, 900);
$theme-accent: mat.define-palette($palette-orange, 600, 100, 700);
$theme-warn: mat.define-palette($palette-red, 200);

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
));

// Replace background color
$background-color: #fff;
$theme-color: map-get($theme, 'color');
$color-background-palette: map-get($theme-color, 'background');
$color-background-palette: map-merge($color-background-palette, (background: $background-color));
$modified-theme-color: map-merge($theme-color, (background: $color-background-palette));
$theme: map-merge($theme, (color: $modified-theme-color));

$angular-dark-theme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
));

body.dark-theme {
  @include mat.all-component-colors($angular-dark-theme);
}

@mixin setVariables($theme) {

  $primary-palette: map-get($theme, 'primary');
  $accent-palette: map-get($theme, 'accent');
  $warn-palette: map-get($theme, 'warn');

  :root {
    --primary: #{mat.get-color-from-palette($primary-palette, 700)};
    --primary-900: #{mat.get-color-from-palette($primary-palette, 900)};
    --primary-contrast: #{mat.get-contrast-color-from-palette($primary-palette, 700)};

    --accent: #{mat.get-color-from-palette($accent-palette, 700)};
    --accent-contrast: #{mat.get-contrast-color-from-palette($accent-palette, 700)};

    --warn: #{mat.get-color-from-palette($warn-palette, 700)};
    --warn-contrast: #{mat.get-contrast-color-from-palette($warn-palette, 700)};

    --warn-100: #{mat.get-color-from-palette($warn-palette, 100)};
    --warn-100-contrast: #{mat.get-contrast-color-from-palette($warn-palette, 100)};

    --primary-text: #{$dark-primary-text};
    --primary-text-contrast: #{$light-primary-text};

    --gray-light: #e5e5e5;
    --gray-medium: #999999;

    --background: #{$background-color};
  }
  :root .dark-theme {
    --primary-text: #{$light-primary-text};
    --primary-text-contrast: #{$dark-primary-text};

    --gray-light: #0a0a0a;
    --gray-medium: #666666;

    --background: #212529;
  }
}

@include mat.all-component-themes($theme);
@include setVariables($theme);
